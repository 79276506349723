header {
  display: flex;
  flex-direction: row;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
}

.header-buttons div {
  margin: auto 5px;
}

.run-code img {
  height: 37px;
  width: 45px;
  padding: 0 2px;
  margin: 0;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown button {
  border: none;
  background-color: var(--editer-gray);
  margin: 0;
  padding: 7px 0;
  width: 115px;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown button,
li {
  text-transform: capitalize;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown button,
.dropdown-content {
  font-size: medium;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--editer-light-gray);
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  padding: 0;
  width: 115px;
  margin: 0;
  z-index: 5;
  border-radius: 0 0 3% 3%;
  list-style-type: none;
  text-align: center;
  margin-top: -1px;
}

.dropdown-content li {
  margin: 0;
  padding: 5px 1px;
}

.dropdown-content li:hover {
  font-size: large;
  font-weight: bold;
}

/* .dropdown-content p:last-of-type {
  font-size: 0.75em;
} */

.tooltiptext {
  visibility: hidden;
  width: 90px;
  color: var(--editer-white);
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  right: 3px;
  top: 45px;
  font-size: medium;
}

.run-code:hover .tooltiptext {
  visibility: visible;
}

.header-intro img {
  height: 45px;
  width: 45px;
  padding: 5px 15px;
}

@media only screen and (max-width: 600px) {
  .dropdown button {
    padding: 7px 0;
    width: 95px;
  }

  .dropdown-content {
    width: 95px;
  }
}
