.code-layout {
  height: var(--editer-height);
  min-height: var(--editer-min-height);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  border-bottom: 8px solid var(--editer-gray);
  border-top: 5px solid var(--editer-gray);
}

.code-results {
  flex-basis: 35%;
  border-left: 5px solid var(--editer-gray);
  color: var(--editer-white);
}

#text-edit {
  height: var(--editer-height) !important;
  flex-basis: 65%;
}

#code-results {
  height: var(--editer-height) !important;
  width: 100% !important;
  border-bottom: 8px solid var(--editer-gray);
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: wait;
}

#overlay .spinner {
  margin: 50vh auto;
}

/* #code-results,
#text-edit {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@media only screen and (max-width: 769px) {
  #text-edit {
    font-size: medium !important;
  }

  #code-results {
    font-size: medium !important;
  }
}

@media only screen and (max-width: 769px) {
  .code-layout {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    border-bottom: 0 solid var(--editer-gray);
    border-top: 10px solid var(--editer-gray);
  }

  .code-results {
    flex-basis: 100%;
    border-left: 0 solid var(--editer-gray);
    color: var(--editer-white);
    height: 250px !important;
  }

  .ace-content {
    height: 500px !important;
  }

  #text-edit {
    height: 500px !important;
    flex-basis: 100%;
    width: 100% !important;
    border-bottom: 8px solid var(--editer-gray);
  }

  #code-results {
    height: 250px !important;
    width: 100% !important;
    flex-basis: 100%;
    width: 100% !important;
    border-bottom: 0 solid var(--editer-gray);
  }

  footer {
    border-top: 10px solid var(--editer-gray);
  }
}
