ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

.larger-screen-warn {
  font-size: small !important;
  margin-bottom: 15px !important;
  display: none;
}

@media only screen and (max-width: 769px) {
  .hide-tablet {
    display: none !important;
  }

  .larger-screen-warn {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none !important;
  }
}
